import { TemplateEnum } from '@eo-storefronts/eo-core'
import { useEffect, useState } from 'react'
import getCategoryLabelTemplateService
  from '~/src/components/category/category-label/styles/GetCategoryLabelTemplateService'
import StylesCategoryLabelServiceInterface
  from '~/src/components/category/category-label/styles/StylesCategoryLabelServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useEoValue } from '~/src/hooks/useEoState'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { FIRM_THEME_SELECTOR } from '~/src/stores/firm'
import { useLocation } from 'react-router-dom'

const useGetCategoryLabelTemplate = (): StylesCategoryLabelServiceInterface => {
  const { pathname } = useLocation()
  const muiTheme = useCustomMuiTheme()
  const firmTheme = useEoValue(FIRM_THEME_SELECTOR)
  const [ pageStyle, setPageStyle ] = useState<TemplateEnum | undefined>()

  useEffect(() => {
    if (!firmTheme) {
      return
    }

    if (pathname.includes(RoutesEnum.PRODUCTS)) {
      return setPageStyle(firmTheme?.components?.productsList ?? firmTheme?.pages?.products)
    }

    setPageStyle(firmTheme?.pages?.categories)
  }, [ pathname, firmTheme ])

  return getCategoryLabelTemplateService(pageStyle, muiTheme, firmTheme)
}

export default useGetCategoryLabelTemplate
